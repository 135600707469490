// hooks
import { BrandAttributes } from "../../../helpers/hooks/BrandQuery";

const results = [
  {
    brand: "bpress",
    brandMediaUrl: BrandAttributes["bpress"]?.icon,
    brandMediaTitle: BrandAttributes["bpress"]?.title,
    brandMediaWidth: BrandAttributes["bpress"]?.width,
    imageSrc: BrandAttributes["bpress"]?.src,
    imageAlt: BrandAttributes["bpress"]?.title,
    displayWater: true,
    displayGas: false,
    displayCold: false,
    displayTemp: false,
    fittingMaterial: "Copper",
    minTempRange: "-35°C",
    maxTempRange: "+110°C",
    minPressure: "-80kPa",
    maxPressure: "1600kPa",
    sizeRange: "15-50mm",
    url: BrandAttributes["bpress"]?.url,
    query: "/products/?brandTitle=>B<+Press&applications=water",
  },
  {
    brand: "bpress",
    brandMediaUrl: BrandAttributes["bpress"]?.icon,
    brandMediaTitle: BrandAttributes["bpress"]?.title,
    brandMediaWidth: BrandAttributes["bpress"]?.width,
    imageSrc: BrandAttributes["bpress"]?.srcHighTemp,
    imageAlt: BrandAttributes["bpress"]?.title,
    displayWater: false,
    displayGas: false,
    displayCold: false,
    displayTemp: true,
    fittingMaterial: "Copper",
    minTempRange: "-20°C",
    maxTempRange: "+160°C",
    minPressure: "-80kPa",
    maxPressure: "1600kPa",
    sizeRange: "15-25mm",
    url: BrandAttributes["bpress"]?.url,
    query: "/products/?brandTitle=>B<+Press&applications=high-temperature",
  },
  {
    brand: "bpress",
    brandMediaUrl: BrandAttributes["bpress"]?.icon,
    brandMediaTitle: BrandAttributes["bpress"]?.title,
    brandMediaWidth: BrandAttributes["bpress"]?.width,
    imageSrc: BrandAttributes["bpress"]?.srcGas,
    imageAlt: BrandAttributes["bpress"]?.title,
    displayWater: false,
    displayGas: true,
    displayCold: false,
    displayTemp: false,
    fittingMaterial: "Copper",
    minTempRange: "-20°C",
    maxTempRange: "+70°C",
    minPressure: "-80kPa",
    maxPressure: "1600kPa",
    sizeRange: "15-50mm",
    url: BrandAttributes["bpress"]?.url,
    query: "/products/?brandTitle=>B<+Press&applications=gas",
  },
  {
    brand: "bpressxl",
    brandMediaUrl: BrandAttributes["bpressxl"]?.icon,
    brandMediaTitle: BrandAttributes["bpressxl"]?.title,
    brandMediaWidth: BrandAttributes["bpressxl"]?.width,
    imageSrc: BrandAttributes["bpressxl"]?.src,
    imageAlt: BrandAttributes["bpressxl"]?.title,
    displayWater: true,
    displayGas: false,
    displayCold: false,
    displayTemp: false,
    fittingMaterial: "Copper",
    minTempRange: "-35°C",
    maxTempRange: "+110°C",
    minPressure: "-80kPa",
    maxPressure: "1600kPa",
    sizeRange: "65-100mm",
    url: BrandAttributes["bpressxl"]?.url,
    query: "/products/?brandTitle=>B<+Press+XL&applications=water",
  },
  {
    brand: "bpressxl",
    brandMediaUrl: BrandAttributes["bpressxl"]?.icon,
    brandMediaTitle: BrandAttributes["bpressxl"]?.title,
    brandMediaWidth: BrandAttributes["bpressxl"]?.width,
    imageSrc: BrandAttributes["bpressxl"]?.srcGas,
    imageAlt: BrandAttributes["bpressxl"]?.title,
    displayWater: false,
    displayGas: true,
    displayCold: false,
    displayTemp: false,
    fittingMaterial: "Copper",
    minTempRange: "-20°C",
    maxTempRange: "+70°C",
    minPressure: "-80kPa",
    maxPressure: "1600kPa",
    sizeRange: "65-100mm",
    url: BrandAttributes["bpressxl"]?.url,
    query: "/products/?brandTitle=>B<+Press+XL&applications=gas",
  },
  {
    brand: "bmaxipro",
    brandMediaUrl: BrandAttributes["bmaxipro"]?.icon,
    brandMediaTitle: BrandAttributes["bmaxipro"]?.title,
    brandMediaWidth: BrandAttributes["bmaxipro"]?.width,
    imageSrc: BrandAttributes["bmaxipro"]?.src,
    imageAlt: BrandAttributes["bmaxipro"]?.title,
    displayWater: false,
    displayGas: false,
    displayCold: true,
    displayTemp: false,
    fittingMaterial: "Copper",
    minTempRange: "-40°C",
    maxTempRange: "+121°C",
    minPressure: "0kPa",
    maxPressure: "4800kPa",
    sizeRange: '1/4"-1 1/8"',
    url: BrandAttributes["bmaxipro"]?.url,
    query: "/products/?brandTitle=>B<+Maxipro&applications=refrigerant",
  },

  {
    brand: "bpressstainlesssteel",
    brandMediaUrl: BrandAttributes["bpressstainlesssteelv2"]?.icon,
    brandMediaTitle: BrandAttributes["bpressstainlesssteelv2"]?.title,
    brandMediaWidth: BrandAttributes["bpressstainlesssteelv2"]?.width,
    imageSrc: BrandAttributes["bpressstainlesssteel"]?.src,
    imageAlt: BrandAttributes["bpressstainlesssteel"]?.title,
    displayWater: true,
    displayGas: false,
    displayCold: false,
    displayTemp: false,
    fittingMaterial: "Stainless Steel",
    minTempRange: "-35°C",
    maxTempRange: "+110°C",
    minPressure: "0kPa",
    maxPressure: "1600kPa",
    sizeRange: "15-54mm",
    url: BrandAttributes["bpressstainlesssteel"]?.url,
    query: "/products/?brandTitle=>B<+Press+Stainless+Steel&applications=water",
  },
];

export default results;
